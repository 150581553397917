.container{
    padding: 1rem;
}

.videos{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr ;
    gap: 1rem;

    /* padding: 1rem; */
 }
 
 .img{
     height: 13rem;
     position: relative;
     cursor: pointer;

     /* width: 12rem; */
     /* width: 5rem; */
 }

 img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
 }

 .name{
    /* width: 88%; */
    width: 100%;
    /* backdrop-filter: brightness(50%); */
    background-color: rgba(255, 255, 255, 0.9);
    position: absolute;
    bottom: 0;
    z-index: 99;
   
    /* padding:  0.1rem; */
    font-size: 1.25rem;
    font-weight: 500;
    
 }

 .text{
    padding: 0.4rem 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 0;
    overflow-wrap: anywhere;
    color: black;
    text-decoration: none;
    /* overflow: auto; */
 }

 @media only screen and (max-width:1080px){
    .videos{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr  ;
        gap: 1rem;
    
        /* padding: 1rem; */
     }
 }





 @media only screen and (max-width:628px){
    .videos{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr  ;
        gap: 1rem;
    
        /* padding: 1rem; */
     }
 }