.topic_container{
    /* width: 100%; */
    padding: 0rem 1rem;
}

.text{
    padding: 1rem;
    background-color: rgb(245, 209, 13);
    font-size: 1.25rem;
    font-weight: 600;
    border-radius: 10px;
    display: flex;
    gap: 0.5rem;
}



/* .icon{
    font-size: 2rem;
} */