.main{
    background-color: black;
    width: 100%;
    height: 100%;
    min-height: 100dvh; 
}


.container{
    background-color: black;
    width: 100%;
    height: 100%;
    min-height: 100dvh;
}

.player{
    /* width: 100%; */
    height: 35% !important;
}

.React_player{
    height: 15rem;
}



.animation{
    height: 100vh;
    
}
