.container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5rem;
   
}

.logo{
    /* width: 100%; */
    width: 10rem;
    height: 100%;
    padding: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.menuBar{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem;
    color: white;
    font-size: 2rem;
}

.img{
    height: 100%;
}