.slide{
    height: 12rem;
    width: 100%;
}

.img{
    background-size: cover;
    border-radius: 10px;
    /* height: 10rem; */
    position: relative;
    width: 100%;
}

img{
    width: 100%;
}

.name{
    width: 88%;
    /* backdrop-filter: brightness(50%); */
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    position: absolute;
    bottom: 0;
    z-index: 99;
    margin: 0;
    padding:  0.5rem 1rem;
    font-size: 1.25rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 0.5rem;
 }
