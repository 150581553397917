.img{
    background-size: cover;
    border-radius: 10px;
    position: relative;
}

/* .title{
    background-color: rgba(1, 1, 1, 0.5);
    height: 15%;
    position: absolute;
    font-size: 20px;
    padding: 10px;
    backdrop-filter: blur(10px);
    right: 50%;
    text-align: center;
    color: white;

} */


.name{
    width: 70%;
    
    clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);
    font-family: "Fredoka One", cursive;
    /* backdrop-filter: brightness(50%); */
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    position: absolute;
    bottom: 0;
    z-index: 99;
    margin: 0;
    padding:  0.5rem 1rem;
    font-size: 1.25rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 0.5rem;
 }