.container{
    /* display: flex; */
    /* justify-content: center;
    align-items: center; */
    background-color: black;
    width: 100%;
    min-height: 100dvh;
}


.slider{
    height: 15rem;
    padding: 1rem;
}


.animation{
    height: 100vh;
}

