.container{
    /* padding: 1rem; */
    width: 100%;
    
}

.sub_container{
    padding: 1rem;
    background-color: rgb(10, 1, 43);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* justify-content: center;
    align-items: center; */

}


.title{
    color: white;
    font-size: 1.5rem;
    margin: 0;

}

.options{
    display: flex;
    justify-content: flex-start;
    gap: 1.5rem;
    font-size: 1.25rem;
    font-weight: 500;
    color: white;
}


.share,.like,.views{
    display: flex;
    gap: 0.5rem;
}